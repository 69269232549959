import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
        maxWidth: 1100,
        width: '100%',
        margin: '0 auto',
        padding: theme.spacing(6, 2),
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(9, 2),
        },
      },
      narrow: {
          maxWidth: 600
      }
  })
);

function Section(props) {
    const { children, narrow, ...rest } = props
    const classes = useStyles();

  return (
    <section className={clsx(classes.root, narrow ? classes.narrow : {})} {...rest}>
        {children}
    </section>
  );
}

export default Section;
