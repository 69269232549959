import React from "react";
import "./App.css";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/Main";
import About from "./components/About";
import Contact from "./components/Contact";
import {
  createMuiTheme,
  Divider,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import HowItWorks from "./components/HowItWorks";
import ProductPhotos from "./components/ProductPhotos";
import Team from "./components/Team";

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
}));

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

function App() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg">
          <Header />
          <main className={classes.offset}>
            <Main />
            <Divider id="about" />
            <HowItWorks />
            <About />
            <ProductPhotos />
            <Divider id="contact" />
            <Contact />
            <Team />
          </main>
          <Footer />
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
