import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, CardMedia } from "@material-ui/core";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    margin: "15px",
    "@media (max-width:600px)": {
      margin: "5px",
    },
  },
  flexBoxBox: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
    "@media (max-width:600px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  cardMain: {
    position: "relative",
    width: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mediaLong: {
    height: theme.spacing(70),
    width: theme.spacing(40),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  mediaSquare: {
    height: theme.spacing(47),
    width: theme.spacing(47),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  grid: {
    marginBottom: theme.spacing(4),
  },
}));

export default function ProductPhotos() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} className={classes.grid}>
          <Card className={classes.cardMain}>
            <Box>
              <CardContent className={classes.cardContent}>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ margin: "15px" }}
                >
                  The Torr Dynamics R30 Window
                </Typography>
                <Typography component="h1" variant="subtitle1" align="center">
                  Torr Dynamics window has tested in the R30 range and can be
                  customized to meet the insulatory requirements of any region
                  or client.
                </Typography>
              </CardContent>
            </Box>
            <Section style={{ padding: "0", marginBottom: "20px" }}>
              <Box className={classes.flexBoxBox}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.mediaLong}
                    image="https://torrdynamics.s3.amazonaws.com/images/feature.jpg"
                    title="window feature image"
                  />
                </Card>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.mediaSquare}
                    image="https://torrdynamics.s3.amazonaws.com/images/detail.jpg"
                    title="window detail image"
                  />
                </Card>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.mediaLong}
                    image="https://torrdynamics.s3.amazonaws.com/images/featureAngle.jpg"
                    title="angled window feature image"
                  />
                </Card>
              </Box>
            </Section>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
