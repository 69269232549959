import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    color: "#658D1B",
  },
}));

export default function HowItWorks() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h2" align="center" gutterBottom>
            The Torr System
          </Typography>
          <Typography variant="h5" align="center" paragraph>
            The Torr system uses vacuum insulation technology to provide the
            world’s most energy efficient greenhouse. Torr Dynamics R30
            insulated glass allows traditional greenhouse operators to save up
            to 90% on heating costs and heating infastructure.
          </Typography>
        </Container>
      </div>
    </React.Fragment>
  );
}
