import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box } from "@material-ui/core";
// import FeaturedTiles from "./FeaturedTiles";


// theme.typography.h2 = {
//   fontSize: '3rem',
//   '@media (min-width:600px)': {
//     fontSize: '2.2rem',
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '3.75rem',
//   },
// };

// theme.typography.h5 = {
//   fontSize: '1.5rem',
//   '@media (min-width:600px)': {
//     fontSize: '1.5rem',
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '1.75rem',
//   },
// };

const useStyles = makeStyles((theme) => ({
  mainPage: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage:
      "url(https://torrdynamics.s3.amazonaws.com/images/greenhouse.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginTop: "125px",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.35)",
  },
  mainPageContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12),
      paddingRight: 0,
    },
  },
  arrow: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    paddingBottom: "15px",
    color: theme.palette.common.white,
  },
}));

export default function Main() {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.mainPage}>
        <div className={classes.overlay} />
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className={classes.mainPageContent}>
                <Typography
                  component="h1"
                  variant="h2"
                  color="inherit"
                  gutterBottom
                >
                  Imagine a 90% reduction in greenhouse heating costs.
                </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                Meet the Torr Dynamics R30 Window.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <a key="about arrow" href="#about">
          <Box className={classes.arrow}>
            <ExpandMoreIcon fontSize="large" />
          </Box>
        </a>
      </Paper>
    </>
  );
}
