import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardMedia, Grid } from "@material-ui/core";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  flexBoxBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    "@media (max-width:600px)": {
      flexDirection: "column",
    },
  },
  card: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    margin: "40px",
    "@media (max-width:600px)": {
      margin: "20px",
    },
  },
  media: {
    height: theme.spacing(60),
    width: theme.spacing(82),
    "@media (max-width:600px)": {
      height: theme.spacing(25),
      width: theme.spacing(35),
    },
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    margin: "0px",
  },
}));

export default function Team() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} className={classes.grid}>
          <Section style={{ padding: "0" }}>
            <Box className={classes.flexBoxBox}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image="https://torrdynamics.s3.amazonaws.com/images/group.jpg"
                  title="team"
                />
              </Card>
            </Box>
          </Section>
        </Grid>
      </Grid>
    </>
  );
}
