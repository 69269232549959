import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, CardContent, CardMedia } from "@material-ui/core";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    margin: "35px",
    "@media (max-width:600px)": {
      margin: "20px",
    },
  },
  flexBoxBox: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width:600px)": {
      flexDirection: "column",
    },
  },
  cardMain: {
    position: "relative",
    width: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  media: {
    height: theme.spacing(47),
    width: theme.spacing(47),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  grid: {
    marginBottom: theme.spacing(4),
  },
}));

export default function About() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} className={classes.grid}>
          <Card className={classes.cardMain}>
            <Section style={{ padding: "0" }}>
              <Box
                className={classes.flexBoxBox}
                style={{ justifyContent: "center" }}
              >
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.media}
                    image="https://s3.amazonaws.com/torrdynamics.com/standardGH_illustrationOnly.png"
                    title="standard heat diagram"
                  />
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    Standard Greenhouse
                  </Typography>
                </Card>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.media}
                    image="https://s3.amazonaws.com/torrdynamics.com/torrGH_illustrationOnly.png"
                    title="torr heat diagram"
                  />
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                    align="center"
                  >
                    Torr Dynamics Greenhouse
                  </Typography>
                </Card>
              </Box>
            </Section>
            <Box>
              <CardContent className={classes.cardContent}>
                <Typography
                  variant="h4"
                  align="center"
                  style={{ marginBottom: "15px" }}
                >
                  Powered by Torr’s Insulating Glazing Technology
                </Typography>
                <Typography component="h1" variant="subtitle1" align="center">
                  Torr Dynamics patented technology utilizes the same principles
                  as a Yeti Cup to keep coffee hot or ice cold. Torr Dynamics
                  window has tested in the R30 range and can be customized to
                  meet the insulatory requirements of any region or client. The
                  benefits to an R30 window are immense. For comparison, a
                  single-paned glass window is an R1, and a six-inch thick
                  insulated brick wall is an R30. The window system is extremely
                  energy efficient allowing for better and more economical
                  control of the internal environment.
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        {/* <Grid item xs={12} md={6}>
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image="https://s3.amazonaws.com/torrdynamics.com/diagram.png"
            title="torr heat diagram"
          />
        </Card>
      </Grid> */}
      </Grid>
    </>
  );
}
