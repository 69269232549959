import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { AppBar, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: "left",
    paddingLeft: "0px",
    "@media (max-width:600px)": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    color: "#658D1B",
  },
  appbar: {
    position: "fixed",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    paddingLeft: "0px",
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <a href="/">
            <img
              style={{
                width: "150px",
                margin: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              src="https://torrdynamics.s3.amazonaws.com/images/logo.png"
              alt="torr dynamics logo"
            />
          </a>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <a
              key="about"
              href="#about"
              className={classes.toolbarLink}
              style={{ textDecoration: "none" }}
            >
              <Typography variant="h6">About</Typography>
            </a>
            {/* <Typography variant="h6" style={{color: "#658D1B", margin: "5px"}}>|</Typography>
          <a
            key="projects"
            href="#projects"
            className={classes.toolbarLink}
            style={{ textDecoration: "none" }}
          >
            <Typography variant="h6">Partners</Typography>
          </a> */}
            <Typography
              variant="h6"
              style={{ color: "#658D1B", margin: "1px" }}
            >
              |
            </Typography>
            <a
              key="contact"
              href="#contact"
              className={classes.toolbarLink}
              style={{ textDecoration: "none" }}
            >
              <Typography variant="h6">Contact</Typography>
            </a>
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
