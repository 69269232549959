import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Avatar, Box, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    display: "flex",
    marginTop: "15px",
    justifyContent: "center",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#658D1B",
    width: theme.spacing(20),
    height: theme.spacing(20),
    backgroundSize: "cover",
    "& img": {
      objectPosition: "top",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}));

const contactInfo = [
  {
    icon: "https://torrdynamics.s3.amazonaws.com/images/kattman.jpg",
    name: "Tyler Kattmann",
    title: "Co-Founder, Director of Engineering",
    email: "tkattmann@torrdynamics.com",
  },
  {
    icon: "https://torrdynamics.s3.amazonaws.com/images/elias.jpg",
    name: "Christian Elias",
    title: "Co-Founder, President",
    email: "celias@torrdynamics.com",
  },
  {
    icon: "https://torrdynamics.s3.amazonaws.com/images/price.jpg",
    name: "Chris Price",
    title: "Vice President",
    email: "cprice@torrdynamics.com",
  },
  {
    icon: "https://torrdynamics.s3.amazonaws.com/images/bob.jpg",
    name: "Bob Scherer",
    title: "Business Development Manager",
    email: "ras@sipinc.com",
  },
];

export default function Contact() {
  const classes = useStyles();

  return (
    <Container>
      <Typography variant="h3" className={classes.title}>
        Contact Us
      </Typography>
      <Grid container spacing={5} className={classes.grid}>
        {contactInfo.map((contactInfo) => (
          <Grid item xs={12} md={5} key={contactInfo.email}>
            <Card className={classes.card}>
              <Box>
                <CardContent className={classes.cardContent}>
                  <Avatar
                    className={classes.avatar}
                    src={contactInfo.icon}
                  ></Avatar>
                  <Typography
                    component="h2"
                    variant="h4"
                    style={{ margin: "15px" }}
                  >
                    {contactInfo.name}
                  </Typography>
                  <Typography variant="subtitle1" paragraph align="center">
                    {contactInfo.title}
                  </Typography>
                  <Typography
                    variant="h6"
                    paragraph
                    align="center"
                    style={{ color: "#658D1B" }}
                  >
                    {contactInfo.email}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
